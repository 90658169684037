<template>
<section class="hero"  v-bind:class="{ otherImage: img !== undefined }">
  <div class="hero-body">
    <div class="container with-btn">
      <p v-if="title" class="title title-l bold" :class="{'has-back-button': hasBackButton }">
        <font-awesome-icon v-if="hasBackButton" :icon="['fas', 'chevron-left']" @click="$router.go(-1)" class="icon-back" /> 
        <span @click="$router.go(-1)">{{ title }}</span>
      </p>
    <button class="btn button" @click="downloadDiploma()" v-if="hasButton">
    <span class="content-button"
      ><font-awesome-icon
        class="icono"
        :icon="['fal', 'award']"
      />
      <span class="text-m black save-text">Generar diplomas</span></span
    >
  </button>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: "ImageHeader",
  props: {
    title: String,
    img: String,
    hasButton: Boolean,
    hasBackButton: Boolean
  },
  methods: {
    downloadDiploma() {
      this.$emit('openModalDiploma');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';
.with-btn {
  display: flex;
  justify-content: space-between;
}
.hero {
  position: relative;
  background: url("../assets/img/Fondo-cabecera.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 250px;
}

.otherImage{
  background: url("../assets/img/Fondo-cabecera-nino.jpg") no-repeat center center !important;
}

.hero:before {
content: " ";
position: absolute;
top: 0;
z-index: 0;

width: 100%;
left: 0;
right: 0;
bottom: 0;
margin: 0;
padding: 0;
}

.title {
  color: white;
  padding-bottom: 1.5rem;
  text-align: left;
}

.hero.is-medium .hero-body {
  padding-bottom: 6.5rem !important;
  padding-top: 6.5rem !important;
}

.btn {
  background-color: white;
  // width: 100%;
  height: 100%;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  .content-button {
    display: flex;
    align-items: center;
  }

  .icono {
    font-size: 20px;
    color: $primary;
    margin-right: 10px;
  }
}

.has-back-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  .icon-back {
    margin-right: 8px;
  }
}
</style>
